main {
    width: auto;
    height: auto;
    display: flex;
    /* background-color: #141A1D; */
    /* background-color: rgb(36, 36, 36); */
    flex-direction: column;
    margin-top: 40px;
    position: relative;
}

.messages {
    display: flex;
    height: auto;
    width: 200px;
    position: fixed;
    right: 10px;
    flex-direction: column;
    z-index: 2;
}

.message {
    height: 100px;
    width: 200px;
    display: flex;
    background-color: grey;
    margin-bottom: 10px;
}
.message.good {
    background-color: green;
}
.message.bad {
    background-color: red;
}

@media (max-width:500px)  { 
    .Toastify__toast-container {
        width: 70vw !important;
        margin: 10px 0 0px 10px !important;
    }

    .Toastify__toast {
        border-radius: 4px !important;
        margin-bottom: 10px !important;
    }
}