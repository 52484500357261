.modal {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 3;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.6);
    transition: 1s;
    animation-name: item_open;
    animation-duration: 0.4s;
}
.modal.hide {
    display: none;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0);
    transition: 1s;
}
.modal.close {
    background: none;
    pointer-events: none;
    animation-name: item_close;
    animation-duration: 0.4s;
}

.modal_content {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    max-width: 100vw;
    /* background-color: rgb(36, 36, 36); */
    background-color: #242424;
    margin: 5vh 0px 0px 0px;
    border-radius: 10px 10px 0px 0px;
    /* transition: transform 300ms linear; */
    animation-name: item_appearence;
    animation-duration: 0.4s;
    overflow-y: scroll;
    scroll-behavior: auto;
    overscroll-behavior: none !important;
}
.modal_content::-webkit-scrollbar{
    width: 0px;
}

.recharts-responsive-container {
    display: flex;
    min-height: 30%;
}

@media (min-width: 600px) {
    .modal_content {
        max-width: 600px;
    }
}

@keyframes item_appearence {
    from   {margin-top: 100vh;}
    to   {top: 5vh;}
}
@keyframes item_open {
    from   {background-color: rgba(0, 0, 0, 0);}
    to   {background-color: rgba(0, 0, 0, 0.6);}
}
@keyframes item_close {
    from   {background-color: rgba(0, 0, 0, 0.6);}
    to   {background-color: rgba(0, 0, 0, 0);}
}

#chart {
    overflow: hidden;
}

.apexcharts-tooltip {
    color: rgb(207, 207, 207);
}
/* .apexcharts-menu-icon {
    visibility: hidden;
} */
.apexcharts-xaxis-label {
    font-weight: 400 !important;
}
.apexcharts-tooltip-title, .apexcharts-tooltip.apexcharts-theme-light, .apexcharts-tooltip-title, .apexcharts-tooltip-series-group {
    background: #25272a !important;
}
.apexcharts-tooltip-title {
    padding-left: 10px !important;
}

.chart_time {
    display: flex;
    margin: 0px 0 30px 30px;
    font-size: 14px;
}

.Toastify__toast{
    width: 330px
}

.MuiSlider-root {
    padding: 20px 0 20px 0 !important;
}
.MuiSlider-markLabel {
    top: 30px !important;
}
  