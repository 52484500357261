
/* .MuiSlider-markLabel {
    color: white;
} */

/* .MuiChartsLegend-root {
    transform: translateY(-30px) !important;
} */


.MuiChartsTooltip-root {
    background-color: #212427 !important;
    border: 1px solid #e3e3e3 !important;
    font-size: 12px !important;
}
.MuiChartsTooltip-root * {
    color: white !important;
}
.MuiBox-root {
    width: 12px !important;
    height: 12px !important;
    border: none !important;
}