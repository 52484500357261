#history_chart_sales {
    height: 270px;
    width: 100vw;
    /* margin-bottom: 50px; */
    overflow: visible !important;
    margin-left: -20px;
    margin-top: -10px;
}

#history_chart_sales > .apexcharts-canvas > .apexcharts-svg > foreignObject > .apexcharts-legend > .apexcharts-legend-series {
    display: flex !important;
    justify-content: center !important;
    background-color: #2a2a2a;
    padding: 8px 10px;
    border-radius: 6px;
    z-index: 2;
}

#history_chart_sales > .apexchartshistory_chart_sales > .apexcharts-svg > foreignObject > .apexcharts-legend {
    overflow: visible !important;
    z-index: 1000;
}

.history_page_calendar {
    height: fit-content;
    border: 1px solid #aeaeae;
    background: #1f1f1f !important;
}

.history_page_calendar > .react-datepicker__month-container > .react-datepicker__header > .react-datepicker__current-month {
    color: white;
}
.history_page_calendar > .react-datepicker__month-container > .react-datepicker__header > 
.react-datepicker__day-names > .react-datepicker__day-name {
    color: white;
}
.history_page_calendar > .react-datepicker__month-container > .react-datepicker__month > 
.react-datepicker__week > .react-datepicker__day--keyboard-selected {
    background: none;
}
.history_page_calendar > .react-datepicker__month-container > .react-datepicker__header{    
    background: none;
}
.history_page_calendar > .react-datepicker__month-container > .react-datepicker__month > 
.react-datepicker__week > .react-datepicker__day {   
    color: #fff;
}
.history_page_calendar > .react-datepicker__month-container > .react-datepicker__month > 
.react-datepicker__week > .react-datepicker__day:hover {   
    background-color: #ffa977;
}

.history_page_calendar > .react-datepicker__month-container > .react-datepicker__month > 
.react-datepicker__week > .react-datepicker__day--disabled {   
    color: #8e8e8e;
    background: none;
}
.history_page_calendar > .react-datepicker__month-container > .react-datepicker__month > 
.react-datepicker__week > .react-datepicker__day--disabled:hover {   
    background: none;
}

.history_page_calendar > .react-datepicker__month-container > .react-datepicker__month > 
.react-datepicker__week > .react-datepicker__day--in-selecting-range {
    background-color: #ec884e;
}
.history_page_calendar > .react-datepicker__month-container > .react-datepicker__month > 
.react-datepicker__week > .react-datepicker__day--in-range {
    background-color: #E3702C;
}

.history_page_calendar2 {
    display: none;
    margin: 26px 20px 20px 0;
}

@media (min-width: 1500px) {
    .history_page_calendar2 {
        display: inline-block;
    }
}