.search_area {
    display: flex;
    height: 35px;
    width: 90%;
    max-width: 450px;
    align-items: center;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 20px;
    margin: 6px auto 15px auto;
}

.search_icon {
    width: 20px;
    margin-left: 10px;
}

.clear_icon {
    width: 16px;
    margin-right: 8px;
    cursor: pointer;   
    padding: 6px; 
}

.search_area input {
    display: flex;
    height: 100%;
    width: 100%;
    margin: 0px 0 0 10px;
    padding: 0;
    background: none;
    border: 0;
    color: rgb(207, 207, 207);
    font-size: 18px;
    margin-left: 3px;
    text-indent: 10px;
}
.search_area input:focus {
    outline: none;
}