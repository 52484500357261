.item {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    /* border: 1px solid black; */
    width: 100%;
}
.item::-webkit-scrollbar{
    width: 1px;
}

.header {
    display: block;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 20px;
    padding-bottom: 0;  
    position: relative;  
}
.header_item {
    display: block;
    height: 350px;
    width: auto;
    margin: 0 auto 0 auto;
    user-select: none;
    pointer-events: none;
}

.close_modal {
    position: absolute;
    width: 20px;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
@media (max-width:500px)  { 
    .header_item {
        height: 60vw;
    }
}

.name {
    align-self: center;
    font-size: 22px;
    color: rgb(255, 255, 255);
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    margin: 0 0 10px 0;
    cursor: pointer;
}

.prices {
    display: flex;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 15px 20px 10px 20px;
    justify-content: space-between;
    user-select: none;
}
.prices.unset {
    pointer-events: none;
}

.price {
    display: flex;
    position: relative;
    height: 40px;
    width: calc(50% - 7px);
    /* border: 1px solid rgb(96, 92, 71); */
    border-radius: 7px;
    align-items: center;
}

.price input {
    height: 100%;
    padding: 0;
    background: none;
    border: 0;
    color: rgb(255, 255, 255);
    font-size: 16px;
    margin-left: 60px;
    width: 100%;
    transition: 0.3s linear;
}
.price2 input {
    margin-left: 65px;
}

.price p {
    position: absolute;
    left: 10px;
    transition: 0.4s linear;
}

.price input:focus {
    outline: none;
}

.price_percent {
    height: 18px;
    background-color:#242424;
    position: absolute;
    top: -9px;
    left: 10px;
    font-size: 14px;
    padding: 0 5px 0 5px;
    color: rgb(124, 124, 124);
}

.confirm_prices {
    display: flex;
    height: 40px;
    width: 100%;
    font-size: 20px;
    border: 1px solid rgba(255, 153, 0, 1);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    color: white;
}

.confirm_prices:hover {
    cursor: pointer;
}

.module_opener {
    display: flex;
    position: absolute;
    height: 26px;
    width: calc(100% - 32px);
    background-color: inherit;
    color: rgb(207, 207, 207);
    font-size: 16px;
    padding: 6px 6px 6px 15px;
    align-items: center;
    z-index: 2;
    bottom: 0px;
    justify-content: center;
    border-radius: 4px;
}
.module_opener img {
    height: auto;
    width: 26px;
    filter: invert(60%);
    transition: 0.5s;
    pointer-events: none;
    user-select: none;
    transition: 0s;
}

.currency {
    display: flex;
    margin: 6px 0 6px 20px;
    font-size: 18px;
}

.top {
    display: flex;
    position: relative;
    min-height: 50px;
    max-height: 50px;
    width: calc(100% - 52px);
    margin: 12px 20px 0px 20px;
    padding: 6px;
    padding-bottom: 36px;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 6px;
    transition: 0.5s ease;
    overflow: hidden;
    background-color: inherit;
    box-shadow: 0px 0px 18px rgb(0, 0, 0);
    cursor: pointer;
    /* border: 1px solid black; */
}

.top>p {
    width: 100%;
    display: flex;
    font-size: 20px;
    justify-content: center;
}

.top .column {
    display: flex;
    width: 28%;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
}

@keyframes blinker {
    0%   {opacity: 0%;}
    50%   {opacity: 100%;}
    100%   {opacity: 0%;}
}

.top .column div {
    display: flex;
    position: relative;
    margin: auto;
    height: 18px;
    margin: 2px 0 2px 0;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
}
.top .column div.current div {
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    animation: blinker 1s infinite;
}
.top .column div.current {
    color: rgba(255, 153, 0, 1);
}

.chart {
    display: flex;
    padding: 0 12px 0 0px;
}