.headerOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 100px;
}
.headerOptions > div {
    height: 22px;
    margin: 0 20px 0 0;
}
.headerOptions > div > a {
    font-size: 16px;
}
@media (max-width: 649px) {
    .headerOptions {
        display: none;
    }
}