body {
    margin : 0px;
    /* background-color: rgb(36, 36, 36); */
    background-color: #1f1f1f;
}
body::-webkit-scrollbar{
    width: 0px;
}

p {
    margin: 0;
}

* {
    font-family: "Inter";
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}