.group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    transition: 0.3s;
}

/* .group.open {
    max-height: fit-content;
}    */

.group_head {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    user-select: none;
    cursor: pointer;
}
.group_head > * {
    pointer-events: none;

}
.group_head p {
    color: white;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.group_head img {
    transition: 0.3s;
}

.item {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    flex-direction: column;
}

.logo {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
}

.section {
    display: flex;
    width: 100%;
    height: 50;
    flex-wrap: wrap;
    flex-direction: column;
}
.section a {
    width: fit-content;
    color: #E8E8E8;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    position: relative;
}

.hint {
    display: flex;
    position: absolute;
    background-color: #363636;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    right: -16px;
    top: -6px;
    color: #E8E8E8;
    font-size: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.hint:hover > .hint_description {
    display: flex;
}

.hint_description {
    display: none;
    position: absolute;
    width: 240px;
    height: fit-content;
    background-color: #363636;
    top: calc(50% + 10px);
    left: calc(50% + 10px);
    z-index: 1;
    font-size: 14px;
    padding: 10px;
    font-family: 'Inter', sans-serif;
    border-radius: 6px;
}
@media (max-width: 550px) {
    .hint_description {
       left: -250px;
    }
}

.toggle_section {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgb(46, 46, 46);
    border-radius: 10px;
    margin-top: 20px;
    color: white;
    padding: 15px;
    box-sizing: border-box;
}
.toggle_section span {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.annotation {
    display: flex;
    padding: 0 10px;
    color: #8A8A8A;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin-top: 5px;
}

.statistic {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: 58px auto;
    gap: 10px
}

.top_statistic_holder {
    display: flex;
    width: auto;
    grid-column: 1/-1;
    flex-direction: column;
    /* overflow: hidden; */
    overflow: scroll;
}
.top_statistic_holder::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
}

.holders_color {
    background-color: #252525;
}

.top_statistic {
    display: flex;
    position: relative;
    height: 57.5px;
    width: 100%;
    /* background-color: rgb(46, 46, 46); */
    border-radius: 10px;
    padding: 10px 30px 10px 20px;
    color: white;
    box-sizing: border-box;
    margin-top: 20px;
    cursor: pointer;
}

.counts {
    display: flex;
    height: calc(100% - 20px);
    width: 6px;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    left: 10px;
    pointer-events: none;
}

.count {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: rgb(68, 68, 68);
}
.count.current {
    background-color: white;
}

.top_statistic_group {
    display: flex;
    width: 100%;
    justify-content: space-around;
    pointer-events: none;
}

.top_statistic_statistic {
    display: flex;
    height: 100%;
    width: 80px;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
}
.top_statistic_statistic > span {
    display: flex;
    justify-content: center;
    font-family: 'Inter', sans-serif;
}

.border {
    display: flex;
    width: 1px;
    height: 100%;
    background-color: #D9D9D9;
}

.statistic_left, .statistic_right {
    display: flex;
    width: auto;
    box-sizing: border-box;
    flex-direction: column;
    border-radius: 10px;
}

.statistic_right {
    padding-top: 10px;
}

.budget {
    display: flex;
    width: 100%;
    height: fit-content;
    /* background-color: rgb(46, 46, 46); */
    border-radius: 10px;
    flex-direction: column;
    padding: 10px 0px;
}

.rates {
    display: flex;
    width: auto;
    height: fit-content;
    /* background-color: rgb(46, 46, 46); */
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px 0px;
    flex-wrap: wrap;
}

.section_head {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 8px;
    user-select: none;
    cursor: pointer;
    background: none;
    box-sizing: border-box;
    color: white;
}
.section_head > p {
    display: flex;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 17px;
}

.budget_container {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 300px;
    align-self: center;
}
.budget_container > div {
    height: auto !important;
    width: auto !important;
}

.rate {
    display: flex;
    flex-direction: column;
    color: white;
    padding: 6px 0px 0px 10px;
}
.rate > * {
    font-family: 'Inter', sans-serif;

}

.number {
    display: flex;
    font-size: 24px;
    font-weight: 500;
}

.market {
    display: flex;
    font-size: 14px;
    font-weight: 300;
}