main {
    box-sizing: border-box;
}

.login {
    display: flex;
    position: relative;
    width: 400px;
    height: 300px;
    background-color: rgb(51, 51, 51);
    background: none;
    /* background-color: rgb(138, 56, 56); */
    margin: auto;
    margin-top: 20vh;
    border-radius: 20px;
    box-sizing: border-box;
    flex-direction: row;
    overflow: hidden;
}

.block1 {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-shrink: 0;
    position: absolute;
    left: 0%;
    top: 40px;
    transition: 0.5s;
}

.block2 {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-shrink: 0;
    position: absolute;
    left: 100%;
    top: 40px;
    transition: 0.5s;
}

.field {
    height: 30px;
    width: 80%;
    padding: 10px;
    background: none;
    color: rgb(207, 207, 207);
    border: 1px solid rgb(148, 148, 148);
    border-radius: 10px;
    font-size: 20px;
    margin: 15px auto 0px auto;
}
.field:focus {
    outline: none;
    border-color: rgb(207, 207, 207);
}
.field:focus + .name {
    color: rgb(241, 241, 241);
}

.name {
    position: absolute;
    color: rgb(173, 173, 173);
    font-size: 14px;
    background-color: rgb(36, 36, 36);
    top: 7px;
    left: 45px;
    padding: 0 4px 0 4px;
}

.title {
    height: 40px;
    width: 100%;
    color: rgb(207, 207, 207);
    text-align: center;
    font-size: 28px;
}

.confirm {
    height: 40px;
    width: calc(80% + 20px);
    margin: 10px auto 0 auto;
    background-color: rgb(121, 121, 121);
    border: 1px solid rgb(121, 121, 121);
    border-radius: 10px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}
.confirm:hover {
    background-color: rgb(148, 148, 148);
    color: rgb(255, 255, 255);
}

.MessageBox {
    display: flex;
    width: 80%;
    margin: 5px auto 5px auto;
}

@media (max-width:500px)  { 
    .login {
        width: 100%;
    }
    .phone_name {
        left: 12%;
    }
}