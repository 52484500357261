.all-items {
    display: grid;
    padding: 0px 100px 10px 100px;
}

@media (max-width:650px)  { 
    .all-items {
        padding: 0px 10px;
    }
}

.user {
    margin-top: 15px;
}
.user_header {
    display: flex;
    justify-content: center;
    align-items: center;
}
.user_header > p {
    color: rgb(255, 255, 255);
    font-size: 26px;
    margin-left: 6px;
    margin-right: 6px;
}

.user_status {
    display: flex;
    position: relative;
    height: 22px;
    width: 40px;
    margin-right: -10px;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
}
.user_status img {
    position: absolute;
    width: 12px;
    transition: 0.2s;
}
.user_status div {
    height: 4.5px;
    width: 18px;
    border-radius: 10px;
    background-color: rgb(77, 77, 77);
    align-self: center;
}

.user_reload {
    width: 20px;
    filter: brightness(200%);
    cursor: pointer;
}
.user_reload.in_process {
    animation: loading 2s infinite linear;
}

@keyframes loading {
    0%   {transform: rotate(0deg);}
    100%   {transform: rotate(360deg);}
}

.items-opener {
    display: flex;
    height: 35px;
    width: auto;
    max-width: 500px;
    /* background-color: rgb(27, 27, 27); */
    background-color: #303030;
    color: #ffffff;
    font-size: 18px;
    margin: 10px auto 10px auto;
    padding: 4px 6px 4px 15px;
    align-items: center;
    cursor: pointer;;
}

.items-opener img {
    height: auto;
    width: 30px;
    margin-left: auto;
    filter: invert(100%);
    transition: 0.5s;
    pointer-events: none;
    user-select: none;
}

.items-opener p {
    pointer-events: none;
    user-select: none;
}

.items-opener.open img {
    transform: rotate(180deg);
    user-select: none;
}

.group-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-rows: auto;
    gap: 20px;
}

.unavailable-items {
    max-height: 0px;
    overflow: hidden;
    transition: opacity 0.7s, max-height 0.5s ease;
}

.items-opener + .unavailable-items {
    opacity: 0;
}
.items-opener.open + .unavailable-items {
    opacity: 1;
    transition: opacity 0.7s, max-height 0.5s ease;
}

@media (max-width:500px)  { 
    .group-items {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 10px;
    }
}

.item_container {
    position: relative;
    color: white;
}

.item {
    display: flex;
    margin: 0px;
    flex-direction: column;
    /* border: 2px solid rgb(148, 3, 3); */
    border-radius: 5px;
    width: auto;
    height: 100%;
    padding: 0px 10px 0px 10px;
    /* background-image: url('https://buff.163.com/static/images/item_bg.png');  */
    /* background: rgb(2,0,36); */
    /* background: radial-gradient(circle, rgb(151, 151, 151) 0%, rgb(116, 116, 116) 35%, rgb(54, 54, 54) 100%);background-position:center; */
    background-color: #303030;
    background-size: cover;
    position: relative;
    transition: 0.2s;
    animation-duration: 0.4s;
    user-select: none;
    box-sizing: border-box;
}

.item * {
    color: rgb(255, 255, 255);
}
.item > img {
    width: 100%;
    pointer-events: none;
} 

.item_container.scale {
    cursor: pointer;
    /* transform: scale(0.95); */
    transition: 0.1s;
    /* outline: 2px solid rgb(148, 3, 3); */
}
.item_container.scale > .item {
    background-color: #3b3b3b;
}

.item_info.online {
    color: rgb(15, 170, 41);
}

.item_info.offline {
    color: red;
}

.item input {
    display: flex;
}

.item.offline-item {
    opacity: 20%;
}

.item.unavailable-item {
    pointer-events: none;
}
.item_sold {
    display: flex;
    position: absolute;
    width: 100px;
    height: 40px;
    background: rgb(199, 19, 19);
    pointer-events: none;
    border-radius: 5px;
    top: calc(50% - 20px);
    left: calc(50% - 50px);
    z-index: 1;
    animation: blinker 2s infinite ease-in;
}
.item_sold p {
    margin: auto;
    font-size: 20px;
    color: white;
}
.item_sold ~ .item {
    animation: blinker2 2s infinite ease-in;
    /* background-color: rgb(156, 28, 28); */
}
@keyframes blinker {
    0% {opacity: 5%;}
    50% {opacity: 100%;}
    100% {opacity: 5%;}
}
@keyframes blinker2 {
    0% {background-color: none;}
    50% {background-color: rgb(156, 28, 28);}
    100% {background-color: none;}
}

.item_info {
    display: flex;
    margin-top: 8px;
}

.item_wear {
    display: flex;
    position: absolute;
    bottom: 26px;
    color: rgb(206, 206, 206);
}

.item_percent {
    display: flex;
    position: absolute;
    bottom: 7px;
}

.item_position {
    display: flex;
    position: absolute;
    right: 12px;
    top: 7px;
    background-color: rgba(0,0,0,0.4);
    border-radius: 4px;
    padding: 2px 4px 2px 4px;
    pointer-events: none;
}

.item_status {
    display: flex;
    position: absolute;
    right: 8px;
    bottom: 6px;
    background-color: rgba(0,0,0,0.15);
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
}
.item_status img {
    height: 18px;
    pointer-events: none;
    user-select: none;
}
.item_status.online img {
    animation-name: switch_on;
    animation-duration: 0.4s;
}
.item_status.offline img {
    animation-name: switch_off;
    animation-duration: 0.4s;
    transform: rotate(180deg);
}
@keyframes switch_on {
    from   {transform: rotate(180deg);}
    to   {transform: rotate(0deg);}
}
@keyframes switch_off {
    from   {transform: rotate(0deg);}
    to   {transform: rotate(180deg);}
}

.item_position > div {
    display: flex;
    align-items: center;
}

.item_position > div > img {
    width: 10px;
    margin-left: 2px;
}

.edit {
    display: flex;
    width: auto;
    height: 14px;
    margin-left: 5px;
}